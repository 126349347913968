import React, { memo } from 'react'
import { Box, Chip, Container, Divider, styled, useTheme } from '@mui/material'
import {
  GatsbyImage,
  IGatsbyImageData,
  getImage,
  withArtDirection,
} from 'gatsby-plugin-image'
import { CalendarBlank } from 'phosphor-react'

// @ts-ignore
import { DatoCmsButton } from '__generated__/gatsby-types'

import BackgroundPlx from 'components/atoms/BackgroundPlx'
import FixedHeightHeroContainer from 'components/atoms/FixedHeightHeroContainer'
import Sun from 'components/atoms/Sun'
import CurveMask from 'components/atoms/CurveMask'
import CornerBox from 'components/atoms/CornerBox'
import MainHeading from 'components/molecules/MainHeading'

export interface EventHeroPropsBase {
  imageLandscape: IGatsbyImageData
  imagePortrait: IGatsbyImageData
  imageAlt: string
  keyId: string
  heading: string
  date: string
  eventType: string
}

interface EventHeroProps extends EventHeroPropsBase {
  children: React.ReactNode
  contentBgColor: string
}

// prettier-ignore
const StyledFixedHeightHeroContainer = styled(FixedHeightHeroContainer)(({ theme }) => ({
    background: theme.palette.secondary.main,
    position: 'relative',
}))

const HeroImage = styled(GatsbyImage)(({ theme }) => ({
  height: '100%',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    display: 'block',
    height: '0',
    paddingTop: '100%',
  },
}))

const EventHero = ({
  keyId,
  imageLandscape,
  imagePortrait,
  imageAlt,
  heading,
  eventType,
  date,
  children,
  contentBgColor,
}: EventHeroProps) => {
  const theme = useTheme()

  const images = withArtDirection(
    // @ts-ignore
    getImage(imageLandscape),
    [
      {
        media: `(max-width: ${theme.breakpoints.values.md}px)`,
        image: getImage(imagePortrait),
      },
    ]
  )

  const parallaxData = [
    {
      start: 'self',
      end: 'self',
      endOffset: '200vh',
      properties: [
        {
          startValue: 0,
          endValue: 50,
          property: 'translateY',
          unit: 'vh',
        },
      ],
    },
  ]

  const topSpace = 180
  const nagativeMargins = {
    xs: -(theme.fixedHeroHeights.xs - topSpace + 40),
    sm: -(theme.fixedHeroHeights.sm - topSpace + 40),
    md: -(theme.fixedHeroHeights.md - topSpace),
    lg: -(theme.fixedHeroHeights.lg - topSpace),
    xl: -(theme.fixedHeroHeights.xl - topSpace),
  }

  return (
    <>
      <StyledFixedHeightHeroContainer>
        <Box height="100%">
          <BackgroundPlx parallaxData={parallaxData}>
            <Sun size="large" color="light" position="bottom-left-xl" />
            <Sun size="xl" color="light" position="top-right-xl" />
          </BackgroundPlx>
        </Box>
      </StyledFixedHeightHeroContainer>

      <CurveMask bgColor={contentBgColor} upShift overflow>
        <Container
          maxWidth="xl"
          sx={{
            position: 'relative',
            zIndex: 10,
            transform: {
              xs: `translateY(${nagativeMargins.xs}px)`,
              sm: `translateY(${nagativeMargins.sm}px)`,
              md: `translateY(${nagativeMargins.md}px)`,
              lg: `translateY(${nagativeMargins.lg}px)`,
              xl: `translateY(${nagativeMargins.xl}px)`,
            },
          }}
        >
          <CornerBox breakpointDown="xs">
            <HeroImage
              image={images}
              alt={imageAlt ? imageAlt : ''}
              imgStyle={{
                borderRadius: theme.shape?.borderRadius,
              }}
            />
          </CornerBox>
        </Container>
      </CurveMask>
      <Box
        mt={{
          xs: `${nagativeMargins.xs}px`,
          sm: `${nagativeMargins.sm}px`,
          md: `${nagativeMargins.md}px`,
          lg: `${nagativeMargins.lg}px`,
          xl: `${nagativeMargins.xl}px`,
        }}
        minHeight="300px"
        bgcolor={contentBgColor}
        position="relative"
        zIndex={20}
      >
        <Container maxWidth="xl" sx={{ textAlign: 'center' }}>
          <Box pt={4} pb={2}>
            <Chip
              color="primary"
              label={eventType}
              sx={{ marginTop: '6px', marginBottom: '6px' }}
            />
            <Chip
              className="with-icon"
              label={date}
              icon={<CalendarBlank size={18} weight="regular" />}
              sx={{
                backgroundColor: 'lightGrey',
                marginTop: '6px',
                marginBottom: '6px',
              }}
            />
          </Box>
          <MainHeading
            text={heading}
            keyId={`heading-${keyId}`}
            breakpoint="xs"
            variant="h1Small"
            component="h1"
          />
          <Divider
            sx={{ mt: { xs: 3, sm: 4, lg: 5 }, mb: { xs: 3, sm: 4, lg: 5 } }}
          />
          {children}
        </Container>
      </Box>
    </>
  )
}

export default memo(EventHero)
