import React, { memo } from 'react'
import { Box, BoxProps, useTheme } from '@mui/material'

// @ts-ignore
import { DatoCmsSocialMediaLink } from '__generated__/gatsby-types'

import SocialButton, {
  SocialButtonCoreProps,
} from 'components/atoms/buttons/SocialButton'

interface ShareSocialButtonsProps extends SocialButtonCoreProps {
  keyId: string
  url: string
  modelName: string
  mailSubject: string
  boxProps?: BoxProps
  verticalPanel?: boolean
}

const ShareSocialButtons = ({
  url,
  variant,
  keyId,
  modelName,
  mailSubject,
  boxProps,
  verticalPanel,
}: ShareSocialButtonsProps) => {
  const theme = useTheme()
  const social = [
    {
      platform: 'facebook',
      name: 'Facebook',
      url: `https://www.facebook.com/sharer/sharer.php?u=${url}`,
    },
    {
      platform: 'twitter',
      name: 'Twitter',
      url: `https://twitter.com/intent/tweet?text=${url}`,
    },
    {
      platform: 'whatsapp',
      name: 'WhatsApp',
      url: `https://wa.me/?text=${url}`,
    },
    {
      platform: 'email',
      name: 'Email',
      url: `mailto:?subject=${mailSubject}&body=${url}`,
    },
  ]

  return (
    <>
      <Box
        mt={verticalPanel ? 0 : 4}
        pt={verticalPanel ? 2 : 0}
        mb={2}
        textAlign="center"
        sx={{
          typography: 'smallText',
          color: 'text.secondary',
          borderTop: verticalPanel
            ? `4px solid ${theme.palette.primary.main}`
            : 'none',
        }}
      >
        {verticalPanel ? 'Share' : `Share this ${modelName}:`}
      </Box>
      <Box
        display="flex"
        alignItems="center"
        width="100%"
        justifyContent="center"
        flexDirection={verticalPanel ? 'column' : 'row'}
        {...boxProps}
      >
        {social.map((social: DatoCmsSocialMediaLink) => {
          return (
            <SocialButton
              key={`${keyId}-${social.platform}-share`}
              href={social.url}
              variant={variant}
              platform={social.platform}
              ariaLabel={`Share on ${social.name}`}
              sx={{ mb: verticalPanel ? '8px' : 0 }}
            />
          )
        })}
      </Box>
    </>
  )
}

export default memo(ShareSocialButtons)
