const createNewsEventsCollection = ({ news, events, limit }) => {
  const combined = [...news, ...events]

  const sorted = [...combined].sort((a, b) => {
    return new Date(b.node.date) - new Date(a.node.date)
  })

  return limit ? [...sorted].slice(0, limit) : [...sorted]
}

export default createNewsEventsCollection
