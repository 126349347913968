import React, { memo } from 'react'
import { Coin, ClockClockwise, Money, PersonSimpleRun } from 'phosphor-react'

import {
  DatoCmsEventTime,
  DatoCmsAdmission,
  DatoCmsRegistrationFee,
  DatoCmsMinimumSponsorship,
  DatoCmsActivity,
  DatoCmsDuration,
  // @ts-ignore
} from '__generated__/gatsby-types'

import EventTime from './components/EventTime'
import EventDetailListItem from './EventDetailListItem'

type Detail =
  | DatoCmsEventTime
  | DatoCmsAdmission
  | DatoCmsRegistrationFee
  | DatoCmsMinimumSponsorship
  | DatoCmsActivity
  | DatoCmsDuration

export interface AdditionalDetailsProps {
  details: [Detail]
}

const AdditionalDetails = ({ details }: AdditionalDetailsProps) => {
  return (
    <>
      {/* <EventDetailListItem text={d.duration} icon={<Clock />} /> */}

      {details.map((detail: Detail) => {
        switch (detail.model.apiKey) {
          case 'event_time':
            return (
              <EventTime
                key={detail.originalId}
                startTime={detail.time[0]}
                endTime={detail.endTime[0]}
                includeEndTime={detail.includeEndTime}
              />
            )
          case 'activity':
            return (
              <EventDetailListItem
                key={detail.originalId}
                text={detail.activity}
                icon={<PersonSimpleRun />}
              />
            )
          case 'duration':
            return (
              <EventDetailListItem
                key={detail.originalId}
                text={detail.duration}
                icon={<ClockClockwise />}
              />
            )
          case 'minimum_sponsorship':
            return (
              <EventDetailListItem
                key={detail.originalId}
                text={detail.minimumSponsorship}
                secondaryText="Minimum sponsorship"
                icon={<Coin />}
              />
            )
          case 'registration_fee':
            return (
              <EventDetailListItem
                key={detail.originalId}
                text={detail.registrationFee}
                secondaryText="Registration fee"
                icon={<Money />}
              />
            )

          default:
            return null
        }
      })}
    </>
  )
}

export default memo(AdditionalDetails)
