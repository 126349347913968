import React, { memo } from 'react'
import { Clock } from 'phosphor-react'

// @ts-ignore
import { DatoCmsTime } from '__generated__/gatsby-types'

import formatTime from 'helpers/format-time'
import EventDetailListItem from '../EventDetailListItem'

export interface EventTimeProps {
  startTime: DatoCmsTime
  endTime: DatoCmsTime | undefined
  includeEndTime: Boolean
}

const EventTime = ({ startTime, endTime, includeEndTime }: EventTimeProps) => {
  let time = formatTime({ time: startTime })
  if (endTime && includeEndTime) {
    time += ' - ' + formatTime({ time: endTime })
  }

  return <EventDetailListItem text={time} icon={<Clock />} />
}

export default memo(EventTime)
