import React, { memo } from 'react'
import { graphql, PageProps } from 'gatsby'
import { Button, Grid, List } from '@mui/material'
import { CalendarBlank, MapPin } from 'phosphor-react'

// @ts-ignore
import { DatoCmsSeoMetaTags, EventQuery } from '__generated__/gatsby-types'

// @ts-ignore
import createNewsEventsCollection from 'helpers/news-events-collection'

import Layout from 'components/organisms/global/Layout'
import EventHero from 'components/organisms/hero/EventHero'
import ContentComponents from 'components/molecules/contentComponents/ContentComponents'
import ContentLeadText from 'components/molecules/contentComponents/components/ContentLeadText'
import CornerBox from 'components/atoms/CornerBox'
import ShareSocialButtons from 'components/molecules/ShareSocialButtons'
import SwiperLandingSection from 'components/organisms/sections/SwiperLandingSection'
import TextButton from 'components/atoms/buttons/TextButton'
import LineBreaks from 'components/atoms/LineBreaks'
import EventDetailListItem from 'components/molecules/eventDetails/EventDetailListItem'
import AdditionalDetails from 'components/molecules/eventDetails/AdditionalDetails'
import EventForm from 'components/organisms/forms/EventForm'

interface Props extends PageProps {
  data: EventQuery
}

const Event = ({ data, location }: Props) => {
  const d = data.datoCmsEvent

  const newsEvents = createNewsEventsCollection({
    news: data.allDatoCmsNewsArticle.edges,
    events: data.allDatoCmsEvent.edges,
    limit: 6,
  })

  let date = d.dateFormatted
  if (d.includeEndDate && d.endDateFormatted) {
    date = `${d.dateFormatted} - ${d.endDateFormatted}`
  }

  return (
    <Layout
      metadata={{
        seoTags: d.seoMetaTags,
      }}
      forceSolidNav
    >
      <EventHero
        eventType={d.eventType}
        contentBgColor="common.white"
        keyId={d.originalId}
        imageLandscape={d.imageLandscape.gatsbyImageData}
        imagePortrait={d.imagePortrait.gatsbyImageData}
        heading={d.headingNode.childMarkdownRemark.html}
        date={d.dateFormatted}
      >
        <Grid container spacing={3} sx={{ textAlign: 'left' }}>
          <Grid item md={7} xl={8}>
            <ContentLeadText
              text={d.leadText}
              leftAlignText
              sx={{
                pt: { xs: 1, sm: 2, md: 2, lg: 3, xl: 3 },
                pb: { xs: 0, sm: 3, md: 4, lg: 5 },
              }}
            />
            <ContentComponents
              boxProps={{ pb: { xs: 0, md: 8, lg: 10, xl: 13 } }}
              components={d.contentComponents}
              noContainer
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={5}
            lg={4}
            xl={3}
            sx={{ ml: 'auto', pb: { xs: 6 } }}
          >
            <CornerBox
              bgcolor="lightGrey"
              breakpointDown="xs"
              borderRadiusSize="small"
              py={3}
              px={3}
            >
              <List>
                <EventDetailListItem text={date} icon={<CalendarBlank />} />
                <EventDetailListItem text={d.locationName} icon={<MapPin />}>
                  {d.locationAddressNode?.childMarkdownRemark?.html && (
                    <LineBreaks
                      text={d.locationAddressNode.childMarkdownRemark.html}
                      breakpoint="xs"
                      keyId={`address-${d.originalId}`}
                    />
                  )}
                  {d.location?.latitude && d.location?.longitude && (
                    <TextButton
                      compact
                      sx={{
                        fontSize: '1.4rem !important',
                        marginTop: '1rem',
                      }}
                      href={`https://www.google.com/maps/search/?api=1&query=${d.location.latitude},${d.location.longitude}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      View on Google Maps
                    </TextButton>
                  )}
                </EventDetailListItem>

                <AdditionalDetails details={d.additionalDetails} />
              </List>
            </CornerBox>
            <EventForm
              buttonLabel={d.formButtonLabel}
              formText={d.formText}
              disclaimer={d.formDisclaimer}
              marketingDisclaimer={d.formMarketingDisclaimer}
              eventTitle={d.cardContent[0].heading}
              eventDate={d.includeEndDate && d.endDate ? d.endDate : d.date}
              eventId={d.originalId}
              successMessage={d.successMessage}
              errorMessage={d.errorMessage}
              submitButtonLabel={d.submitButtonLabel}
            />
            <ShareSocialButtons
              url={location.href}
              keyId={d.originalId}
              variant="border-dark"
              modelName="event"
              mailSubject={`LCS ${d.eventType}: ${d.cardContent[0].heading}`}
            />
          </Grid>
        </Grid>
      </EventHero>
      <SwiperLandingSection
        heading={data?.datoCmsGlobalContent?.moreNewsEventsHeading}
        cards={newsEvents}
        sectionBgColor="lightGrey"
        containerBgProps={{
          bgcolor: 'common.white',
        }}
      />
    </Layout>
  )
}

export default memo(Event)

export const pageQuery = graphql`
  query EventsQuery($originalId: String!) {
    datoCmsEvent(originalId: { eq: $originalId }) {
      originalId
      formText
      formDisclaimer
      formMarketingDisclaimer
      formButtonLabel
      submitButtonLabel
      successMessage
      errorMessage
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      date
      endDate
      dateFormatted: date(formatString: "dddd Do MMMM, YYYY")
      endDateFormatted: endDate(formatString: "dddd Do MMMM, YYYY")
      leadText
      originalId
      slug
      locationName
      locationAddressNode {
        childMarkdownRemark {
          html
        }
      }
      location {
        longitude
        latitude
      }

      includeEndDate
      includeLocationAddress
      eventType
      additionalDetails {
        ... on DatoCmsEventTime {
          ...eventTime
        }
        ... on DatoCmsActivity {
          ...activity
        }
        ... on DatoCmsAdmission {
          ...admission
        }
        ... on DatoCmsMinimumSponsorship {
          ...minimumSponsorship
        }
        ... on DatoCmsRegistrationFee {
          ...registrationFee
        }
        ... on DatoCmsDuration {
          ...duration
        }
      }
      headingNode {
        childMarkdownRemark {
          html
        }
      }
      cardContent {
        heading
      }
      imageLandscape: heroImage {
        gatsbyImageData(
          width: 1200
          layout: FULL_WIDTH
          imgixParams: { ar: "1100:480", fit: "crop", crop: "focalpoint" }
        )
      }
      imagePortrait: heroImage {
        gatsbyImageData(
          width: 900
          layout: CONSTRAINED
          imgixParams: { ar: "1:1.3", fit: "crop", crop: "focalpoint" }
        )
      }
      contentComponents {
        ... on DatoCmsHeading {
          ...contentHeading
        }
        ... on DatoCmsSubHeading {
          ...contentSubHeading
        }
        ... on DatoCmsText {
          ...contentText
        }
        ... on DatoCmsImage {
          ...contentImage
        }
        ... on DatoCmsYoutubeVideo {
          ...contentYouTube
        }
        ... on DatoCmsButton {
          ...button
        }
        ... on DatoCmsDocument {
          ...contentDocument
        }
        ... on DatoCmsButtonGroup {
          ...contentButtonGroup
        }
      }
    }
    allDatoCmsEvent(filter: { originalId: { ne: $originalId } }, limit: 6) {
      edges {
        node {
          ...eventCard
        }
      }
    }
    allDatoCmsNewsArticle(
      filter: { originalId: { ne: $originalId } }
      limit: 6
    ) {
      edges {
        node {
          ...newsCard
        }
      }
    }
    datoCmsGlobalContent {
      moreNewsEventsHeading
    }
  }
`
