import React, { memo, useState } from 'react'
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from '@mui/material'
import { Formik, Form as FormikForm, FormikHelpers } from 'formik'
import { string, boolean, object } from 'yup'
import dayjs from 'dayjs'
import { ThumbsUp } from 'phosphor-react'

import { encodeFormUri } from 'helpers/encodeForm'
import { useMessage } from 'context/MessageContext'
import TextField from 'components/molecules/formFields/TextField'
import Checkbox from 'components/molecules/formFields/Checkbox'

interface Props {
  eventId: string
  eventDate: Date
  eventTitle: string
  buttonLabel: string
  formText: string
  disclaimer: string
  marketingDisclaimer: string
  successMessage: string
  errorMessage: string
  submitButtonLabel: string
}

interface FormValues {
  botField?: string
  eventId: string
  eventTitle: string
  firstName: string
  surname: string
  email: string
  mobile: string
  disclaimer: boolean
  marketingDisclaimer: boolean
}

const EventForm = ({
  eventId,
  eventTitle,
  eventDate,
  buttonLabel,
  formText,
  disclaimer,
  marketingDisclaimer,
  successMessage,
  errorMessage,
  submitButtonLabel,
}: Props) => {
  const [open, setOpen] = useState(false)
  const { dispatch } = useMessage()
  const formName = 'event'

  const isBefore = dayjs(eventDate).isBefore(dayjs())

  const gridItemWidths = {
    xs: 12,
    md: 6,
  }

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmit = (
    values: FormValues,
    actions: FormikHelpers<FormValues>
  ) => {
    fetch('/?no-cache=1', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: encodeFormUri({ 'form-name': formName, ...values }),
    })
      .then(() => {
        dispatch({
          type: 'setMessage',
          messageStatus: 'success',
          message: successMessage,
        })
        actions.resetForm()
        handleClose()
      })
      .catch(() => {
        dispatch({
          type: 'setMessage',
          messageStatus: 'error',
          message: errorMessage,
        })
      })
      .finally(() => actions.setSubmitting(false))
  }

  const Form = () => (
    <Formik
      initialValues={{
        botField: '',
        eventId: eventId,
        eventTitle: eventTitle,
        firstName: '',
        surname: '',
        email: '',
        mobile: '',
        disclaimer: false,
        marketingDisclaimer: false,
      }}
      validationSchema={object().shape({
        firstName: string().required('Please enter your first name'),
        surname: string().required('Please enter your last name'),
        email: string()
          .email('Please supply a valid email address')
          .required('Email address is Required'),
        mobile: string().required('Please enter a valid mobile number'),
        disclaimer: boolean().oneOf([true], 'Please accept our disclaimer'),
      })}
      onSubmit={handleSubmit}
    >
      <FormikForm
        name={formName}
        data-netlify="true"
        data-netlify-honeypot="botField"
        noValidate
      >
        <DialogTitle sx={{ textAlign: 'center', typography: 'h2' }}>
          {buttonLabel}
        </DialogTitle>
        <DialogContent>
          <Container
            maxWidth="md"
            sx={{ pl: '0 !important', pr: '0 !important', mb: 4 }}
          >
            <DialogContentText sx={{ textAlign: 'center' }}>
              {formText}
            </DialogContentText>
          </Container>

          <Box display="none">
            <TextField
              name="botField"
              label="Name"
              textFieldProps={{
                id: 'bot-field',
                type: 'hidden',
              }}
            />
            <input type="hidden" name="form-name" value={formName} />
            <input type="hidden" name="eventId" value={eventId} />
            <input type="hidden" name="eventTitle" value={eventTitle} />
          </Box>

          <Grid container spacing={2}>
            <Grid item {...gridItemWidths}>
              <TextField
                name="firstName"
                label="First Name"
                textFieldProps={{
                  id: 'firstName',
                  fullWidth: true,
                  required: true,
                }}
              />
            </Grid>
            <Grid item {...gridItemWidths}>
              <TextField
                name="surname"
                label="Last Name"
                textFieldProps={{
                  id: 'surname',
                  fullWidth: true,
                  required: true,
                }}
              />
            </Grid>
            <Grid item {...gridItemWidths}>
              <TextField
                name="email"
                label="Email Address"
                textFieldProps={{
                  id: 'email',
                  fullWidth: true,
                  required: true,
                }}
              />
            </Grid>
            <Grid item {...gridItemWidths}>
              <TextField
                name="mobile"
                label="Mobile Telephone"
                textFieldProps={{
                  id: 'mobile',
                  fullWidth: true,
                  required: true,
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Checkbox label={disclaimer} name="disclaimer" />
              <Checkbox
                label={marketingDisclaimer}
                name="marketingDisclaimer"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" type="submit" startIcon={<ThumbsUp />}>
            {submitButtonLabel}
          </Button>
        </DialogActions>
      </FormikForm>
    </Formik>
  )

  return (
    <>
      <Button
        size="large"
        fullWidth
        variant="contained"
        sx={{ mt: 2 }}
        startIcon={<ThumbsUp />}
        onClick={handleClickOpen}
        disabled={isBefore ? true : false}
      >
        {buttonLabel}
      </Button>
      <Box display="none">
        <Form />
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="lg"
        PaperProps={{
          sx: {
            backgroundColor: 'lightGrey',
          },
        }}
      >
        <Form />
      </Dialog>
    </>
  )
}

export default memo(EventForm)
