import React, { memo } from 'react'
import { Box, ListItem, ListItemIcon, ListItemText } from '@mui/material'

interface Props {
  text: string
  secondaryText?: string
  icon: React.ReactNode
  children?: React.ReactNode
}

const EventDetailListItem = ({
  text,
  secondaryText,
  children,
  icon,
}: Props) => {
  return (
    <ListItem alignItems="flex-start" disableGutters>
      <ListItemIcon
        sx={{ color: 'primary.main', svg: { width: '22px', height: '22px' } }}
      >
        {icon}
      </ListItemIcon>
      <Box>
        <ListItemText
          primary={text}
          secondary={secondaryText}
          primaryTypographyProps={{
            sx: { fontSize: '1.6rem', fontWeight: 'semiBold' },
          }}
        />
        {children && (
          <Box sx={{ typography: 'body2', color: 'text.secondary' }}>
            {children}
          </Box>
        )}
      </Box>
    </ListItem>
  )
}

export default memo(EventDetailListItem)
