// @ts-ignore
import { DatoCmsTime } from '__generated__/gatsby-types'

interface FormatTimeProps {
  time: DatoCmsTime
}

const formatTime = ({ time }: FormatTimeProps) => {
  return `${time.hour}:${time.minute.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })}${time.meridiem}`
}

export default formatTime
